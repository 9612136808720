import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import theme from '../theme/src/base-theme';
import { useTheme } from '@emotion/react';

const BaseWidth = ({
  children,
  disableGutters = false,
  maxWidth,
  disablePadding = false,
  className = '',
}) => {
  // TODO Consider this as a bug? Why use imported theme rather than mTheme from hook
  const mTheme = useTheme();
  const basewidthStyle = {
    paddingX: disablePadding ? 0 : mTheme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      paddingX: disablePadding ? 0 : mTheme.spacing(4),
    },

    [theme.breakpoints.up('lg')]: {
      paddingX: disablePadding ? 0 : mTheme.spacing(5),
    },

    [theme.breakpoints.up('xl')]: {
      paddingX: disablePadding ? 0 : mTheme.spacing(6),
    },
  };

  return (
    <Container
      sx={basewidthStyle}
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      className={className}
    >
      {children}
    </Container>
  );
};

BaseWidth.defaultProps = {
  disableGutters: true,
  maxWidth: 'xl',
};

BaseWidth.propTypes = {
  children: PropTypes.node.isRequired,
  disablePadding: PropTypes.bool,
};

export default BaseWidth;
