import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
const PREFIX = 'Loader';

const classes = {
  centered: `${PREFIX}-centered`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.centered}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

const Loader = (props) => {

  return (
    <Root className={classes.centered}>
      <CircularProgress />
    </Root>
  );
};

export default Loader;
