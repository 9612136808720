import React from 'react';
import { styled } from '@mui/material/styles';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
const PREFIX = 'Error404';

const classes = {
  centered: `${PREFIX}-centered`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.centered}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },

  [`& .${classes.icon}`]: {
    width: '8rem',
    height: '8rem',
    transform: 'rotateZ(45deg)',
  },
}));

const Error404 = (props) => {
  return (
    <Root className={classes.centered}>
      <ErrorOutlineIcon className={classes.icon} />
      <div>Page could not be found</div>
    </Root>
  );
};

export default Error404;
