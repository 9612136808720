import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { setContext } from 'apollo-link-context';
import { useAuthState } from '../auth/context';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_API}`, // 'http://localhost:5000/graphql', // 'https://tokyo-crane-262612.appspot.com/graphql', //
});

const authLink = (token) => {
  console.log('Will create auth link from token: ', token);
  return setContext((_, context) => {
    return {
      headers: {
        ...context.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
};

// Handle by onError on query/mutation hook
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );

//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const link = (authState) => {
  return from([
    onError(({ operation, response, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ name, message }) => {
          console.log(
            `[GraphQL error]: Name: ${name} Message: ${message} Response: ${response}`
          );
          return null;
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // To ignore network errors - TODO: add info box about missing network connection
        // response.errors = null;
      }
    }),
    authLink(authState.token),
    httpLink,
  ]);
};

const Apollo = ({ children }) => {
  const authState = useAuthState();
  const devTools = process.env.NODE_ENV === 'production' ? false : true;
  const client = new ApolloClient({
    connectToDevTools: devTools,
    cache: new InMemoryCache({
      typePolicies: {
        ChecklistItem: {
          keyFields: ['ebookid'],
        },
        Checklist: {
          keyFields: ['id'],
          fields: {
            list: {
              merge(existing = [], incoming = []) {
                return [...incoming];
              },
            },
          },
        },
        // User: {
        //   fields: {
        //     checklist: {
        //       merge(existing = [], incoming = []) {
        //         console.log('CHECKLIST E', existing);
        //         console.log('CHECKLIST I', incoming);
        //         return [...incoming];
        //       },
        //     },
        //   },
        // },
        // Loans: {
        //   fields: {
        //     list: {
        //       merge(existing = [], incoming = []) {
        //         console.log('LOANS LIST', existing);
        //         return [...existing, ...incoming];
        //       },
        //     },
        //   },
        // },
        // LoanedItem: {
        //   keyFields: ['id'],
        //   // fields: {
        //   //   id: {
        //   //     merge(existing = [], incoming = []) {
        //   //       console.log('EXISTING', existing);
        //   //       console.log('INCOMING', incoming);
        //   //       return [...existing, ...incoming];
        //   //     },
        //   //   },
        //   // },
        // },
      },
    }),
    link: link(authState),
    credentials: 'include',
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
