import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { jssPreset } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material';
import { useIntl } from 'react-intl';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { isRtl } from './../../../helpers/locales';
import vbTheme from './base-theme';

const Theme = ({ children, dark }) => {
  const intl = useIntl();
  const direction = isRtl(intl.locale) ? 'rtl' : 'ltr';

  const themeWithDirection = createTheme({ ...vbTheme, direction });
  const darkTheme = createTheme(themeWithDirection, {
    palette: {
      primary: {
        main: '#ffa600',
      },
      background: {
        default: '#303030',
        paper: '#424242',
      },
      paper: {
        backgroundColor: '##ffe11e',
      },
      secondary: {
        main: '##00ff66', // '#ee5052', //'#f76378',
      },
      text: {
        primary: '#00ff66',
        secondary: '#ffa600',
      },
    },
  });

  if (dark) {
    //themeWithDirection.palette.mode = 'dark';
    console.log('toggling dark theme in themeprovider');
  }

  // Configure JSS
  const jss = create({
    plugins: [...jssPreset().plugins, direction === 'rtl' ? rtl() : null],
  });

  /**
   * Create a styling provider using v5 component StyledEngineProvider
   *
   * See https://stackoverflow.com/a/69775237 for more info.
   */
  return (
    <StyledEngineProvider injectFirst jss={jss}>
      <ThemeProvider theme={dark ? darkTheme : themeWithDirection}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
};

export default Theme;
