import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AppI18n from '../../app-i18n/src/async';
import Loader from '../../loader';
import { isRtl } from '../../../helpers/locales';
import CountrySettings from '../../../config/country-settings';
import ActiveGUITranslations from '../../../config/active-translations';
import { domainCountry, domainLocale } from '../../../helpers/domain';

const AppContext = React.createContext();

export const AppContextProvider = ({
  children,
  initialValues = {},
  initialCountryCode,
  initialFallbackLocale,
}) => {
  const countryCode = initialCountryCode || domainCountry();
  const fallbackLocale = initialFallbackLocale || domainLocale();
  const countrySettings = new CountrySettings(countryCode);

  const toggleShowSigninModal = () => {
    setAppState({
      ...appState,
      showSigninModal: !appState.showSigninModal,
    });
  };

  const setAppbarShelfSelectButton = (show) => {
    setAppState({
      ...appState,
      showShelfSelect: show,
    });
  };

  // Switches to host language, ie no/sv
  const changeToHostLocale = () => {
    const currentLocale = fallbackLocale;
    const direction = isRtl(currentLocale) ? 'rtl' : 'ltr';

    setAppState({
      ...appState,
      currentLocale,
      direction,
      indexName: null,
      shelfCode: null,
    });
  };

  const setShelfSettings = (locale, indexName, shelfCode) => {
    // Check if there is a translation of GUI in locale
    const currentLocale =
      ActiveGUITranslations.indexOf(locale) === -1 ? fallbackLocale : locale;
    const direction = isRtl(currentLocale) ? 'rtl' : 'ltr';

    // Prevent update if locale or directions are the same as before
    if (appState.currentLocale === locale && appState.direction === direction) {
      return;
    }

    setAppState({
      ...appState,
      currentLocale,
      direction,
      indexName,
      shelfCode,
    });
  };

  const defaultState = {
    countrySettings, // The current host country settings
    countryCode, // The current host countryCode, ie no/se
    currentLocale: domainLocale(), // Current locale is used for translating the GUI
    fallbackLocale, // Used if there is no translation to current locale, is always no/sv
    direction: 'ltr', // Direction of current locale
    indexName: null, // Name of search index
    shelfCode: null, // Code of shelf, the raw code, not the country specific codes that are used for display
    showSigninModal: false, // Sign in is a global functionality
    showShelfSelect: false, // Whether shelf select button in appbar should be visible
    ...initialValues, // Assign values directly when rendering provider, useful in tests
  };

  const [appState, setAppState] = useState(() => defaultState);

  return (
    <AppContext.Provider
      value={{
        ...appState,
        changeToHostLocale,
        setShelfSettings,
        toggleShowSigninModal,
        setAppbarShelfSelectButton,
      }}
    >
      <React.Suspense fallback={Loader()}>
        <AppI18n>{children}</AppI18n>
      </React.Suspense>
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

export const AppContextConsumer = AppContext.Consumer;
export const useAppState = () => useContext(AppContext);
export default AppContext;
