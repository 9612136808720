import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SecureRoute from './../components/auth/secure-route';

import Error404 from './error/404';
import ROUTES from './../config/routes';

// import Signin from './auth/signin';

import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { useAppState } from '../components/app-context/src';

const Start = React.lazy(
  () => import(/* webpackChunkName: "start" */ './start/')
);
const Shelves = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "shelves" */ './shelves/'
    )
);
const User = React.lazy(
  () =>
    import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ './user/')
);
const About = React.lazy(
  () => import(/* webpackChunkName: "about" */ './about/')
);
const CorporateIdentity = React.lazy(
  () => import(/* webpackChunkName: "ci" */ './corporate-identity/')
);

const SummerbookCollection = React.lazy(() => import('./collections/'));

const Routes = ({ children }) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const appState = useAppState();

  // When location changes in app send page view
  useEffect(() => {
    analytics.page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch>
      <Route exact path={`/${ROUTES.ROOT}`} component={Start} />
      <Route path={`/${ROUTES.SHELVES}`} component={Shelves} />
      <SecureRoute path={`/${ROUTES.USER}`} component={User} />
      <Route path={`/${ROUTES.ABOUT}`} component={About} />
      <Route path={`/${ROUTES.CI}`} component={CorporateIdentity} />
      {appState.countrySettings.hasSummerbook() && (
        <Route
          path={`/${ROUTES.COLLECTIONS_SUMMER_BOOK}`}
          component={SummerbookCollection}
        />
      )}
      <Route path="/404" component={Error404} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
