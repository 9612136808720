import PiwikPro, {
  CustomDimensions,
  UserManagement,
} from '@piwikpro/react-piwik-pro';
import { PageViews, CustomEvent } from '@piwikpro/react-piwik-pro';

var customDimensions;

export default function piwikProPlugin() {
  var pluginConfig =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  initialize(pluginConfig);
  customDimensions = pluginConfig.customDimensions;

  return {
    name: 'piwikPro',
    methods: {},
    config: pluginConfig,
    page: ({ payload }) => {
      var event = payload.properties;
      PageViews.trackPageView(event.title);
    },
    track: ({ payload }) => {
      const usedDimensions = [];
      const DEFAULT_ACTION = 'click';

      Object.entries(payload.properties).forEach(([k, v]) => {
        var dimensionID = customDimensions[k];
        if (dimensionID) {
          CustomDimensions.setCustomDimensionValue(dimensionID, v);
          usedDimensions.push(dimensionID);
        }
      });

      // If payload.event.action is undefined, set it to default (click)
      if (!payload.properties?.action) {
        payload.properties.action = DEFAULT_ACTION;
      }

      CustomEvent.trackEvent(
        payload.properties.category,
        payload.properties.action,
        payload.event
      );
      // Will stay with future hits if not deleted
      usedDimensions.forEach((element) => {
        CustomDimensions.deleteCustomDimension(element);
      });
    },
    identify: (id) => {
      if (id === -1) {
        UserManagement.resetUserId();
      } else {
        UserManagement.setUserId(id);
      }
    },
  };
}

function initialize(config) {
  return PiwikPro.initialize(config.containerId, config.containerUrl);
}
