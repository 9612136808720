import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  overrides: {},
  spacing: [5, 7, 10, 12, 15, 20, 30, 40, 60, 80, 100],
  breakpoints: {
    values: {
      xs: 0, //Screens that are 320px or larger
      sm: 375, //Screens that are 375px or larger
      md: 480, //Etc
      lg: 768,
      xl: 1140,
    },
  },
  palette: {
    primary: {
      light: '#168f8c',
      main: '#034650',
      dark: '#000f12',
      contrastText: '#f5f0ee',
    },
    secondary: {
      main: '#f95a62', // '#ee5052', //'#f76378',
      contrastText: '#fff',
    },
    common: {
      white: '#fff',
    },
    pistage: {
      300: '#caead5',
      500: '#afdebf',
    },
    nougat: {
      50: '#fcfbfb',
      100: '#faf7f6',
      200: '#f8f4f2',
      300: '#f5f0ee', // Theme base color
      400: '#e6dcd9',
      500: '#d6c8c3',
      600: '#b5a19a',
    },
    grape: {
      100: '#fae3df',
      200: '#fdc1b9',
      300: '#fdc1b9',
      400: '#fc8d7e',
      500: '#ff645c',
      600: '#ee5052', // Theme base color
      700: '#cd293f',
      800: '#bc1535',
    },
    pe_light: {
      100: '#dfe6e4',
      200: '#c8ddda',
      300: '#9cc9c7',
      400: '#6fb6b3',
      500: '#43a2a0',
      600: '#168f8c', // Theme base color
    },
    petrol: {
      600: '#034650',
      700: '#003e47', // Theme base color
      800: '#01252c',
      900: '#000f12',
    },
    green: {
      500: '#00B978',
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: 14,
    htmlFontSize: 16,
    // See: https://material-ui.com/customization/typography/ for algorithm on computed fontsize thereof.
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: '1px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '53px',
          [theme.breakpoints.up('lg')]: {
            height: '63px',
          },
          // Default tab width is 160px. With custom breakpoints this has to be changed.
          [theme.breakpoints.up('sm')]: {
            minWidth: 'unset',
          },
          '&$selected': {
            backgroundColor: 'white',
          },
        }),
        textColorPrimary: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.spacing(7),
          borderRadius: theme.spacing(5),
          textTransform: 'none',
          fontWeight: 600,
        }),
      },
    },
  },
});

//Book title in book view
theme.typography.h1 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(21),
  lineHeight: 1.3,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(24),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(27),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(33),
  },
};

//Carousel headings
theme.typography.h2 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(18),
  lineHeight: 1.5,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(21),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(24),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(26),
  },
};

//Book title in cards
theme.typography.h3 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(16),
  lineHeight: 1.5,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(17),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(18),
  },
};

//Author name in book view
theme.typography.h4 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(13),
  lineHeight: 1.5,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(15),
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(16),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(17),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(20),
  },
};

//Author name in cards
theme.typography.h5 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 1.5,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: 1.66,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.75,
  },
};

//No reference yet
theme.typography.h6 = {
  // fontFamily: ['Bebas Neue', 'sans-serif'].join(','),
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(11),
  lineHeight: 1.5,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(12),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(13),
  },
};

//Body1
theme.typography.body1 = {
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(15),
  lineHeight: 1.6,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16),
  },
};

theme.typography.subtitle1 = {
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(10),
  lineHeight: 2,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(11),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(12),
  },
};

//Shelf subtitle
theme.typography.subtitle2 = {
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'none',
  fontSize: theme.typography.pxToRem(9),
  lineHeight: 2,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(10),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.pxToRem(11),
  },
};
export default theme;
