import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import BaseRouter from './base-router';
import { AppContextProvider } from './components/app-context/src';
import { FirebaseContextProvider } from './components/firebase-context';
import FeatureFlagsProvider from './components/featureflags-context';

import Theme from './components/theme/src';
import { CssBaseline } from '@mui/material';
import { AuthContextProvider } from './components/auth/context';
import ApolloClient from './components/apollo-client';
import Tracking from './components/tracking';
import { ConsentContextProvider } from './components/consent';


console.log(`

██    ██  █████  ██████  ██      ██████  ███████ ███    ██ ███████ 
██    ██ ██   ██ ██   ██ ██      ██   ██ ██      ████   ██ ██      
██    ██ ███████ ██████  ██      ██   ██ █████   ██ ██  ██ ███████ 
 ██  ██  ██   ██ ██   ██ ██      ██   ██ ██      ██  ██ ██      ██ 
  ████   ██   ██ ██   ██ ███████ ██████  ███████ ██   ████ ███████ 
                                                                   
                                                                   
██████  ██ ██████  ██      ██  ██████  ████████ ███████ ██   ██    
██   ██ ██ ██   ██ ██      ██ ██    ██    ██    ██      ██  ██     
██████  ██ ██████  ██      ██ ██    ██    ██    █████   █████      
██   ██ ██ ██   ██ ██      ██ ██    ██    ██    ██      ██  ██     
██████  ██ ██████  ███████ ██  ██████     ██    ███████ ██   ██    
`);

const app = (
  <AppContextProvider>
    <FirebaseContextProvider>
      <FeatureFlagsProvider>
        <AuthContextProvider>
          <ApolloClient>
            <Theme>
              <ConsentContextProvider
                cookieName="vb-consent"
                cookieOptions={{ sameSite: 'strict' }}
              >
                <Tracking>
                  <CssBaseline />
                  <BaseRouter>
                    {/*
                     * TODO - look into this is necessary or can be moved down
                     */}
                    <App />
                  </BaseRouter>
                </Tracking>
              </ConsentContextProvider>
            </Theme>
          </ApolloClient>
        </AuthContextProvider>
      </FeatureFlagsProvider>
    </FirebaseContextProvider>
  </AppContextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }

// if (process.env.NODE_ENV !== 'production') {
//   import('react-axe').then(axe => {
//     axe.default(React, ReactDOM, 1000);
//     ReactDOM.render(app, document.getElementById('root'));
//   });
// } else {
//   ReactDOM.render(app, document.getElementById('root'));
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
